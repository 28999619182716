button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 20%;
  font-size: 18px;
  border-radius: 8px;
}
button:hover,
.forgetPassword-btn:hover,
a:hover {
  opacity: 0.7;
}
a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}
