.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.inputs {
  width: 90%;
  padding: 15px;
  margin: 15px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
  border-radius: 8px;
}
#root {
  background-color: #c0c0c0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 1;
}
